import { httpRequestDelete, httpRequestGet, httpRequestPatch, httpRequestPost } from "../Host"

export const getTariflarHttp=async(data, token)=>{
    var res=await httpRequestGet('/billing/admin/tarifs/', data, token)
    return(res)
}
export const setTariflarHttp=async(data, token)=>{
    var res=await httpRequestPost('/billing/admin/tarifs/', data, token)
    return(res)
}
export const editTariflarHttp=async(id, data, token)=>{
    var res=await httpRequestPatch('/billing/admin/tarifs/', data, token, id)
    return(res)
}
export const deleteTariflarHttp=async(data, token)=>{
    var res=await httpRequestDelete('/billing/admin/tarifs/', data, token)
    return(res)
}