import { Button, Checkbox, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select, Switch } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from '../store/Store';
import { getCountriesHttp, getHobbiesHttp, getStatesHttp } from '../host/requests/Countries';
import { addImageToUser, blockUserByAdmin, deleteUserImage, editUserFilter, editUserProfile, getUserFilterHttp, getUsersHttp, setUserImageMain } from '../host/requests/User';
import dayjs from 'dayjs';
import { MdDelete } from "react-icons/md";
import { dangerAlert, successAlert } from '../components/Alert';
import {  disabledDate, noAvatar } from '../components/Checkers';
import Compressor from 'compressorjs';
import Header from '../components/Header';
import { useNavigate, useParams } from 'react-router-dom';
import { IoIosWarning } from 'react-icons/io';

const { Option } = Select;
export default function OneUserSelf() {
    const [form] = Form.useForm();
    const {t}=useTranslation()
    const [hobby, sethobby] = useState([]);
    const [number, setnumber] = useState(0);
    const [minage, setminage] = useState(16);
    const [maxage, setmaxage] = useState(99);
    const [countries, setcountries]=useState(null)
    const [hobbies, sethobbies]=useState(null)
    const [filter, setfilter]=useState(null)
    const [user, setuser]=useState(null)
    const [states, setstates]=useState(null)
    const token_access=useStore(state=>state.token_access)
    const setloader=useStore(state=>state.setloader)
    const navigate=useNavigate()
    const [modal, contextHolder] = Modal.useModal();
    const filterOption = (input, option) =>(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
    const {id}=useParams()


    const uploadImage=async(e)=>{
      setloader(true)
      var data=new FormData()
      const file=e.target.files[0]
      
      new Compressor(file, {
        quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
        success: async (compressedResult) => {
       try{
        data.append("media", compressedResult)
        data.append("user", id)
        var res=await addImageToUser(data, token_access)
        getUserById()
      }catch(error){
        dangerAlert("Texnik nosozlik yuzaga keldi. Keyinroq qaytib urinib ko'ring")
        setloader(false)
      }
      
        },
      });
      
      
      
    }

    const deleteImage=async(id_dat)=>{
      try{
        var res=await deleteUserImage(id_dat, token_access)
        getUserById()
      }catch(error){
        dangerAlert("Texnik nosozlik yuzaga keldi. Keyinroq qaytib urinib ko'ring")
      }
      
    }
    const setMainImage=async(id_dat)=>{
      // try{
        var res=await setUserImageMain({image:id_dat}, id, token_access)
        getUserById()
      // }catch(error){
      //   dangerAlert("Texnik nosozlik yuzaga keldi. Keyinroq qaytib urinib ko'ring")
      // }
    }
   

    useEffect(()=>{
      
      if(countries===null){
        getCountries()
       }
       if(hobbies===null){
        getHobbies()
       }
       if(user===null){
        getUserById()
       }
       
    }, [])
    
   const getUserById=async()=>{
    setloader(true)
      try{
        var res=await getUsersHttp({id:id}, token_access)
        console.log(res)
        if(res.data.results.length>0){
          var a=null
          a=res.data.results[0]
          if(a.birthdate!==null){
            a.birthdate=dayjs(a.birthdate, "YYYY-MM-DD")
          }
          if(a.hobbies!==null){
            a.hobbies=a.hobbies.map(item=>(item.id))
          }
          if(a.country!==null){
            getState(a.country.id)
            a.country=a.country.id
          }
          if(a.state!==null){
            a.state=a.state.id
          }
          if(a.has_filters){
             getFilterUserById(a)
          }else{
            setloader(false)
          }
          console.log(a)
            form.setFieldsValue(a)
            setuser(a)
        }else{
          setloader(false)
          navigate('/users')
        }
      }catch{
        setloader(false)
        navigate('/users')
      }
     
     }
     const getFilterUserById=async(a)=>{
      
      try{
        var res=await getUserFilterHttp(id, token_access)
        console.log(res)
        if(res.data!==null){
          var b={}
        b.gender_filter=res.data.gender
        b.age_from=res.data.age_from
        b.age_till=res.data.age_till
        if(res.data.hobbies!==null){
          b.hobbies_filter=res.data.hobbies.map(item=>(item.id))
        }
        if(res.data.countries!==null){
          b.countries_filter=res.data.countries.map(item=>(item.id))
        }
        var c={...a, ...b}
         setuser(c)
         form.setFieldsValue(c)
         setloader(false)
        }
      }catch{
        setloader(false)
        navigate('/users')
      }
     
     }
    const validatePhone = (rule, value, callback) => {
      const phoneRegex = /[+\d]/g;
      if (value && !phoneRegex.test(value)) {
        callback('Please enter a valid phone address');
      } else {
        callback();
      }
    };
    const getCountries=async()=>{
      
      try{
           var res=await getCountriesHttp(null, token_access)
           setcountries(res.data)
      }catch(error){
           console.log(error)
      }
      
    }
    const getHobbies=async()=>{
      
      try{
           var res=await getHobbiesHttp(null, token_access)
           sethobbies(res.data)
      }catch(error){
           console.log(error)
      }
      
    }
    const openModalConfirm=()=>{
      
      modal.confirm({
          title: 'Eslatma !!!',
          icon: <span className='warning_icon'><IoIosWarning /></span>,
          content: "Bloklangan foydanlanuvchining barcha ma'lumotlari tizimdan o'chib ketadi va uning emaili tizim uchun taqiqqa tushadi.",
          okText: 'Ha',
          cancelText: "Yo'q",
          onOk:()=>{deleteData()},
         
        });
  }
  const deleteData=async()=>{
    setloader(true)
    try{
       var res=await blockUserByAdmin({user:id}, token_access)
       successAlert("Foydalanuvchi bloklandi")
       navigate('/users')
    }catch(error){
       dangerAlert("Foydalanuvchi bloklanmadi")
       setloader(false)
    }
}
    const getState=async(val)=>{
      form.setFieldsValue({
        state: null
      });
      try{
           var res=await getStatesHttp({country:val}, token_access)
           
           setstates(res.data)
      }catch(error){
           console.log(error)
      }
      
    }
    const onFinish=async(val)=>{
      setloader(true)
      var filter_data={}
      val.birthdate=val.birthdate.format("DD-MM-YYYY")
      filter_data.age_till=val.age_till
      filter_data.age_from=val.age_from
      filter_data.countries=val.countries_filter
      filter_data.hobbies=val.hobbies_filter
      filter_data.gender=val.gender_filter
      try{
        var res=await editUserProfile(val, token_access, id)
        setuser(res.data)
        var res1=await editUserFilter(filter_data, token_access, id)
        console.log(res1)
        setfilter(res1.data)
         successAlert("Ma'lumot saqlandi")
         setloader(false)
      }catch (error){
        console.log(error)
        dangerAlert("Texnik nosozlik yuzaga keldi. Keyinroq qaytib urinib ko'ring")
        setloader(false)
      }
    }
  
  return (
    <>
     <div className='contentHeader'>
     <Header />
     </div>
    <div className='content'>
    <div className='contentBody'>
    <div className='selfprofile'>
          <Form
    name="form"
    form={form}
   
    labelCol={{
        span: 24,
      }}
      wrapperCol={{
        span: 24,
      }}
      onFinish={onFinish}
      initialValues={{ remember: false }}
      autoComplete='new-password'
  >
        <div className='self_top'>
            <div className='avatar'>
            <div className='avatar_img' style={{backgroundImage:`url(${user!=null && user.main_image!=null?user.main_image.image:noAvatar(user!==null?user.gender:null)})`}}></div>
          </div>
          <Row className='self_row'>
          <Col style={{"padding":"0.5vw"}} lg={24} md={24} sm={24}>
          <h1 className='title_self_profile title_top'>Shaxsiy ma'lumotlar</h1>
          </Col>
          <Col style={{"padding":"0.5vw"}} lg={12} md={24} sm={24}>
          <Form.Item
          hasFeedback
      label="Familiya"
      name="last_name"
    
    >
      <Input className='inputFullName input'/>
    </Form.Item>
    <Form.Item
    hasFeedback
      label="Ism"
      name="first_name"
      rules={[
        {
          min:2,
          required: true,
          message: "Ma'lumot bo'sh bo'lishi mumkin emas."
        },
      ]}
    >
      <Input className='inputFullName input'/>
    </Form.Item>
    <Form.Item
    hasFeedback
      label="Jins"
      name="gender"
      rules={[
        {
         
          required: true,
          message: "Ma'lumot bo'sh bo'lishi mumkin emas."
        },
      ]}
    >
      <Select
      className="select"
      options={[
        {
          value: 'male',
          label: "Erkak",
        },
        {
          value: 'female',
          label: "Ayol",
        },
        {
          value: 'rather not say',
          label: "Boshqa",
        },
       
      ]}
    />
    </Form.Item>
    
<Form.Item
    hasFeedback
      label="Tug'ilgan sana"
      name="birthdate"
      
      rules={[
      
       
        {
          required: true,
          message: "Ma'lumot bo'sh bo'lishi mumkin emas."
         
        },
        
      ]}
    >
      <DatePicker className='date_input' disabledDate={disabledDate} format="DD.MM.YYYY"/>
    </Form.Item>
          </Col>  
          <Col style={{"padding":"0.5vw"}} lg={12} md={24} sm={24}>
          <Form.Item
          hasFeedback
      label="Mamlakat"
      name="country"
      rules={[
        {
          required: true,
          message: "Ma'lumot bo'sh bo'lishi mumkin emas."
        },
      ]}
    >
    <Select
    className="select"
    showSearch
    optionFilterProp="children"
    filterOption={filterOption}
    
    onChange={getState}
    options={countries!==null?countries.map((item, ket)=>({label:item[`name_uz`], value:item.id})):[]}
    />
    </Form.Item>
    <Form.Item
    hasFeedback
      label="Viloyat"
      name="state"
      
      rules={[
        {
          required: true,
          message: "Ma'lumot bo'sh bo'lishi mumkin emas."
        },
      ]}
    >
    <Select
    className="select"
    showSearch
    optionFilterProp="children"
    filterOption={filterOption}
    
    options={states!==null?states.map((item, ket)=>({label:item[`name_uz`], value:item.id})):[]}
    />
    </Form.Item>
    <Form.Item
  hasFeedback
  label="Telefon raqam"
  name="phone"
  initialValue={"+"}
  
>
  <Input  className='input'/>
</Form.Item>
    <Form.Item
          hasFeedback
          
      label="Qiziqish"
      name="hobbies"
      rules={[
        {
          required: true,
          message: "Ma'lumot bo'sh bo'lishi mumkin emas."
        },
      ]}
    >
    <Select
    className="select"
    showSearch
    mode="multiple"
    maxTagCount= 'responsive'
    optionFilterProp="children"
    filterOption={filterOption}
    onChange={(value)=>{ if (value.length <= 3) {
      sethobby(value);
      setnumber(number+1)
    }}}
    options={hobbies!==null?hobby.length>=3?hobbies.filter(x=>hobby.includes(x.id)).map((item, ket)=>({label:item[`name_uz`], value:item.id})):hobbies.map((item, ket)=>({label:item[`name_uz`], value:item.id})):[]}
    />
    </Form.Item>
          </Col>  
          <Col style={{"padding":"0.5vw"}} lg={24} md={24} sm={24}>
          <Form.Item
          hasFeedback
          label="Izoh"
          name="bio"
          rules={[
            {
              min:10,
              required: true,
              message: "Ma'lumot bo'sh bo'lishi mumkin emas."
                },
                {
                  max:250,
                  required: true,
                  message: "Ma'lumot bo'sh bo'lishi mumkin emas."
                    },
              ]}
    >
<Input.TextArea autoSize={{ minRows: 6, maxRows: 6 }}  className='input'/>
    </Form.Item>
          </Col>
          <Col style={{"padding":"0.5vw"}} lg={24} md={24} sm={24}>
          <h1 className='title_self_profile'>Rasmlar</h1>
          <div className='avatars_box'>
            {user!=null && user.images!==null?user.images.map((item, key)=>{
              return(
              <div className='avatars_item' key={key}>
              <div className='avatars_img' style={{backgroundImage:`url(${item.image})`}}></div>
              <div className='avatars_btn'>
              <Button onClick={()=>{deleteImage(item.id)}} className='avatar_delete' type="primary" shape="circle" icon={<MdDelete />} size={'middle'} />
              <Switch disabled={user.main_image!=null && item.id===user.main_image.id?true:false} 
              onChange={()=>{setMainImage(item.id)}}
              checked={user.main_image!=null && item.id===user.main_image.id?true:false} />
              </div>
              </div>)
            }):<></>}
            {
              user!=null && (user.images===null || user.images.length<5)?
              <div className='avatars_item'>
                <div className='add_avatar'>
                  <input onChange={uploadImage} accept="image/png, image/jpeg, image/jpg" type='file' className='input'/>
                  <div className='avatars_img'>
                   <span>+</span>
                  </div>
                </div>
              </div>:<></>
            }
        </div>
          </Col>
          <Col style={{"padding":"0.5vw"}} lg={24} md={24} sm={24}>
            <h1 className='title_self_profile'>Kimni izlayapti</h1>
          </Col>
          <Col style={{"padding":"0.5vw"}} lg={12} md={24} sm={24}>
          <Form.Item
    hasFeedback
      label="Jins"
      name="gender_filter"
      rules={[
        {
         
          required: true,
          message: "Ma'lumot bo'sh bo'lishi mumkin emas."
        },
      ]}
    >
      <Select
      className="select"
      options={[
        {
          value: 'male',
          label: "Erkak",
        },
        {
          value: 'female',
          label: "Ayol",
        },
        {
          value: 'rather not say',
          label: "Boshqa",
        },
       
      ]}
    />
    </Form.Item>
    <Form.Item
          hasFeedback
      label="Yosh (dan)"
      name="age_from"
      rules={[
        {
          type: 'number',
          min: 16,
          max: maxage,
          message: "Ma'lumot bo'sh bo'lishi mumkin emas."
        },
        {
          required: true,
          message: "Ma'lumot bo'sh bo'lishi mumkin emas."
        }
      ]}
    >
      <InputNumber onChange={(val)=>{setminage(val)}} className='input'/>
    </Form.Item>
    <Form.Item
          hasFeedback
      label="Yosh (gacha)"
      name="age_till"
      rules={[
        {
          type: 'number',
          min: minage,
          max: 99,
          message: "Ma'lumot bo'sh bo'lishi mumkin emas."
        },
        {
          required: true,
          message: "Ma'lumot bo'sh bo'lishi mumkin emas."
        }
      ]}
    >
      <InputNumber  onChange={(val)=>{setmaxage(val)}} className='input'/>
    </Form.Item>
          </Col>
          <Col style={{"padding":"0.5vw"}} lg={12} md={24} sm={24}>
          <Form.Item
          hasFeedback
      label="Qiziqishlar"
      name="hobbies_filter"
      rules={[
        {
          required: true,
          message: "Ma'lumot bo'sh bo'lishi mumkin emas."
        },
      ]}
    >
    <Select
    className="select"
    showSearch
    mode="multiple"
    maxTagCount= 'responsive'
    optionFilterProp="children"
    filterOption={filterOption}
    options={hobbies!==null?hobbies.map((item, ket)=>({label:item[`name_uz`], value:item.id})):[]}
    />
    </Form.Item>
   
          <Form.Item
          hasFeedback
      label="Mamlakatlar"
      name="countries_filter"
      rules={[
        {
          required: true,
          message: "Ma'lumot bo'sh bo'lishi mumkin emas."
        },
      ]}
    >
    <Select
    className="select"
    showSearch
    mode="multiple"
    maxTagCount= 'responsive'
    optionFilterProp="children"
    filterOption={filterOption}
    options={countries!==null?countries.map((item, ket)=>({label:item[`name_uz`], value:item.id})):[]}
    />
    </Form.Item>
   
          </Col>

          <div className='btnBox'>
          <Button className='btn_head red_btn' onClick={()=>{openModalConfirm()}} htmlType="button">
          Bloklash
        </Button>
        <Button className='btn_head' htmlType="submit">
          Saqlash
        </Button>
        </div>
        
          </Row>
        </div>
        
        
        </Form>
    </div>
    </div>
    </div>
    {contextHolder}
    </>
  )
}

