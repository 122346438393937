import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import { deleteUsersLikesHttp, getUsersLikesHttp, } from '../host/requests/UsersLikes'
import { useStore } from '../store/Store'
import { Button, Modal, Table } from 'antd'
import { dangerAlert, successAlert } from '../components/Alert'
import {BsTrashFill } from 'react-icons/bs'
import { IoIosWarning } from "react-icons/io";
import Pagination from '../components/Pagination'
import { changeFormatDate, noAvatar } from '../components/Checkers'
import UsersLikesFilter from '../components/UsersLikesFilter'
import { useParams } from 'react-router-dom'
export default function UserLikes() {
    const [data, setData]=useState([])
    const [open_filter, setOpen_filter]=useState(false)
    const [modal, contextHolder] = Modal.useModal();
    const [created_at__date__gte, setcreated_at__date__gte]=useState(null)
    const [created_at__date__lte, setcreated_at__date__lte]=useState(null)
    const [search, setsearch]=useState(null)
    const [page, setpage]=useState(1)
    const [count, setcount]=useState(0)
    const token_access = useStore(state=>state.token_access)
    const setloader = useStore(state=>state.setloader)
    

    useEffect(()=>{
       
       getData()
    },[page, search])
    const getData=async()=>{
      console.log(open_filter)
      if(open_filter){
        setpage(1)
      }
        if(search===null || search.length===0){
            setloader(true)
        }
        try{
            var res=await getUsersLikesHttp({first_name:search, page:open_filter?1:page,
                date_from:created_at__date__gte,
                date_till:created_at__date__lte,
             }, token_access)
            setData(res.data.results)
            setcount(res.data.count)
            setloader(false)
            setOpen_filter(false)
        }catch (error){
            console.log(error)
            setloader(false)
        }
    }
  
  const openModalFilter=()=>{
    setOpen_filter(true)
}
const closeModalFilter=()=>{
    setOpen_filter(false)
}
 
    const openModalConfirm=(id)=>{
        console.log(id)
        modal.confirm({
            title: 'Eslatma !!!',
            icon: <span className='warning_icon'><IoIosWarning /></span>,
            content: "O'chirilgan ma'lumot qaytib tiklanmaydi. Ma'lumotni o'chirishga ishonchingiz komilmi?",
            okText: 'Ha',
            cancelText: "Yo'q",
            onOk:()=>{deleteData(id)},
           
          });
    }
    const deleteData=async(id)=>{
         setloader(true)
         try{
            var res=await deleteUsersLikesHttp(id, token_access)
            successAlert("Ma'lumot o'chirildi")
            getData()
         }catch(error){
            dangerAlert("Ma'lumot o'chirilmadi")
            setloader(false)
         }
    }
   
    const columns = [
        {
            title: '#',
            className:"mini_column",
            dataIndex: 'id',
            render:(data, row,index)=>{return(((page-1)*15)+(index+1))},
        },
        {
            title: '',
            className:"mini_column",
            dataIndex: 'created_by',
            render:(data)=>{return(<div className='table_img' style={{backgroundImage:`url(${data!==null && data.main_image!==null ?data.main_image.media:noAvatar(data!==null?data.gender:null)})`}}></div>)},
        },
        {
            title: 'Like bosgan foydalanuvchi',
            dataIndex: 'created_by',
            render:(data)=>(data.first_name+" "+data.last_name),
        },
        {
            title: '',
            className:"mini_column",
            dataIndex: 'user',
            render:(data)=>{return(<div className='table_img' style={{backgroundImage:`url(${data!==null && data.main_image!==null ?data.main_image.media:noAvatar(data!==null?data.gender:null)})`}}></div>)},
        },
        {
            title: 'Like bosilgan foydalanuvchi',
            dataIndex: 'user',
            render:(data)=>(data.first_name+" "+data.last_name),
        },
      
        {
            title: "Like bosilgan sana",
            dataIndex: 'created_at',
            render:(data)=>(changeFormatDate(data))
        },
       {
            title: "",
            className:"mini_column",
            dataIndex: 'id',
            render:(id)=>{return(<Button type="primary" onClick={()=>{openModalConfirm(id)}} danger shape="circle" icon={<BsTrashFill />} />)},
        }
      ];
  return (
    <>
     <div className='contentHeader'>
       <Header openFilter={openModalFilter} filter={true} setpage={setpage} search={search} setsearch={setsearch}/>
     </div>
    <div className='content'>
    <div className='contentBody'>
    <Table pagination={false} rowKey={(data)=>{return(data.id)}} columns={columns} dataSource={data}/>
    {count>15?<Pagination count={count} page={page} setpage={setpage}/>:<></>}
    {contextHolder}
    <Modal  className='modal_bottom' width={"95%"} footer={false} title={`Filter`} open={open_filter} onOk={closeModalFilter} onCancel={closeModalFilter}>
   <UsersLikesFilter 

created_at__date__gte={created_at__date__gte}
created_at__date__lte={created_at__date__lte}

setcreated_at__date__gte={setcreated_at__date__gte}
setcreated_at__date__lte={setcreated_at__date__lte}

closeModal={closeModalFilter}
getData={getData}
setpage={setpage}
/>
    </Modal>
    </div>
    </div>
    </>
  )
}

