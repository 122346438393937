import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import { deleteAppealsHttp, editAppealsHttp, getAppealsHttp } from '../host/requests/Appeals'
import { useStore } from '../store/Store'
import { Button, Form, Input, Modal, Switch, Table } from 'antd'
import { dangerAlert, successAlert } from '../components/Alert'
import { BsPencilFill, BsTrashFill } from 'react-icons/bs'
import { IoIosWarning } from "react-icons/io";
import Pagination from '../components/Pagination'
import { changeFormatDate } from '../components/Checkers'
export default function Appeals() {
    const [data, setData]=useState([])
    
    const [modal, contextHolder] = Modal.useModal();
    const [search, setsearch]=useState(null)
   
    const [page, setpage]=useState(1)
    const [count, setcount]=useState(0)
    const token_access = useStore(state=>state.token_access)
    const setloader = useStore(state=>state.setloader)
    
    useEffect(()=>{
        getData()
    },[page, search])
    const getData=async()=>{
        if(search===null || search.length===0){
            setloader(true)
        }

        try{
            var res=await getAppealsHttp({name:search, page:page}, token_access)
            setData(res.data.results)
            setcount(res.data.count)
            setloader(false)
        }catch (error){
            console.log(error)
            setloader(false)
        }
    }
  
  
    const openModalConfirm=(id)=>{
        console.log(id)
        modal.confirm({
            title: 'Eslatma !!!',
            icon: <span className='warning_icon'><IoIosWarning /></span>,
            content: "O'chirilgan ma'lumot qaytib tiklanmaydi. Ma'lumotni o'chirishga ishonchingiz komilmi?",
            okText: 'Ha',
            cancelText: "Yo'q",
            onOk:()=>{deleteData(id)},
           
          });
    }
    const deleteData=async(id)=>{
         setloader(true)
         try{
            var res=await deleteAppealsHttp(id, token_access)
            successAlert("Ma'lumot o'chirildi")
            getData()
         }catch(error){
            dangerAlert("Ma'lumot o'chirilmadi")
            setloader(false)
         }
    }
    
   
    const columns = [
        {
            title: '#',
            className:"mini_column",
            dataIndex: 'id',
            render:(data, row,index)=>{return(((page-1)*15)+(index+1))},
           },
           {
            title: 'Murajaatchi',
            dataIndex: 'name',
           },
           {
            title: 'Kontakt',
            dataIndex: 'contact',
           },
           {
            title: 'Murojaat',
            dataIndex: 'text',
           },
           {
            title: 'Murojaat vaqti',
            dataIndex: 'created_at',
            render:(item)=>(changeFormatDate(item))
           },
         
           {
            title: "Ko'rib chiqildi",
            className:"mini_column",
            dataIndex: '',
            render:(data)=>{return(<Switch disabled={data.seen} defaultChecked={data.seen} onChange={()=>{onChange(data.id)}}/>)},
           },
           {
            title: "",
            className:"mini_column",
            dataIndex: 'id',
            render:(id)=>{return(<Button type="primary" onClick={()=>{openModalConfirm(id)}} danger shape="circle" icon={<BsTrashFill />} />)},
           }
      ];
      const onChange=async(id)=>{
            setloader(true)
            try{
             var res=await editAppealsHttp(id, {seen:true}, token_access)
             setloader(false)
             getData()
            }catch(err){
              setloader(false)
              
            } 
      }
  return (
    <>
     <div className='contentHeader'>
     <Header setpage={setpage} search={search} setsearch={setsearch}/>
     </div>
    <div className='content'>
    <div className='contentBody'>
    <Table pagination={false} rowKey={(data)=>{return(data.id)}} columns={columns} dataSource={data}/>
    {count>15?<Pagination count={count} page={page} setpage={setpage}/>:<></>}
       {contextHolder}
    </div>
    </div>
    </>
  )
}

