import { httpRequestDelete, httpRequestGet, httpRequestPatch} from "../Host"

export const getAppealsHttp=async(data, token)=>{
    var res=await httpRequestGet('/admins/contacts/', data, token)
    return(res)
}

export const editAppealsHttp=async(id, data, token)=>{
    var res=await httpRequestPatch('/admins/contacts/', data, token, id)
    return(res)
}
export const deleteAppealsHttp=async(data, token)=>{
    var res=await httpRequestDelete('/admins/contacts/', data, token)
    return(res)
}