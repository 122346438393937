import { httpRequestDelete, httpRequestGet, httpRequestPatch, httpRequestPost } from "../Host"

export const getreasonHttp=async(data, token)=>{
    var res=await httpRequestGet('/admins/goals/', data, token)
    return(res)
}
export const setreasonHttp=async(data, token)=>{
    var res=await httpRequestPost('/admins/goals/', data, token)
    return(res)
}
export const editreasonHttp=async(id, data, token)=>{
    var res=await httpRequestPatch('/admins/goals/', data, token, id)
    return(res)
}
export const deletereasonHttp=async(data, token)=>{
    var res=await httpRequestDelete('/admins/goals/', data, token)
    return(res)
}